import React, { useState } from "react"
import css from "./module.Nodemailer.css"
import collaborate from "./collaborate.jpg"

function NodeMailer(formSubmission) {
  const [subject, setSubject] = useState("")
  const [email, setEmail] = useState("")
  const [text, setText] = useState("")

  const handleSubjectChange = e => {
    setSubject(e.target.value)
    console.log(subject)
  }

  const handleEmailChange = e => {
    setEmail(e.target.value)
    console.log(email)
  }

  const handleTextChange = e => {
    setText(e.target.value)
    console.log(text)
  }

  const onFormSubmit = e => {
    e.preventDefault()
    formSubmission({ subject, email, text })
  }

  async function postNewEmail(event) {
    event.preventDefault()

    const res = await fetch("http://localhost:5000/email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        email,
        subject,
        text,
      }),
    })
    const payload = await res.json()
  }
  return (
    <div id="form-main">
      <div id="form-div">
        <form class="form" id="form1">
          <p class="name">
            <input
              name="name"
              value={subject}
              type="text"
              class="validate[required,custom[onlyLetter],length[0,100]] feedback-input"
              placeholder="Name"
              id="name"
              onChange={handleSubjectChange}
            />
          </p>

          <p class="email">
            <input
              name="email"
              value={email}
              type="text"
              class="validate[required,custom[email]] feedback-input"
              id="email"
              placeholder="Email"
              onChange={handleEmailChange}
            />
          </p>

          <p class="text">
            <textarea
              name="text"
              value={text}
              class="validate[required,length[6,300]] feedback-input"
              id="comment"
              placeholder="Comment"
              onChange={handleTextChange}
            ></textarea>
          </p>

          <div class="submit">
            <input type="submit" value="SEND" id="button-blue" />
            <div class="ease"></div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NodeMailer
